
import {defineComponent, onMounted, ref} from 'vue'
import {ChartCode, ChartType} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Chart from "@/views/chart/Chart.vue";
import BaseClientSelect from "@/views/company/BaseClientSelect.vue";

export default defineComponent({
  name: "Claim",
  components: {BaseClientSelect, Chart},
  setup() {
    const mounted = ref(false);
    onMounted(() => {
      mounted.value =true ;
      setCurrentPageBreadcrumbsWithParams("Claims Dashboard", [])
    })
    const clientId = ref('');
    return {
      mounted,
      clientId,
      ChartType,
      ChartCode,
    }
  }
})
